@import "./src/scss/variables";

.leaderboards {
  background-color: $veryDarkGrey;
  color: $white;

  @include mediabreakpointup(md) {
    background-image: url(../../images/leaderboards_bg.jpg);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .leaderboard_header_title {
    color: $white;
  }

  .leaderboard_body {
    border-color: transparent;
  }
}

.leaderboards_nav {
  margin-bottom: 1.5em;
  text-align: center;

  @include mediabreakpointup(sm) {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .MuiButton-root {
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    border-radius: 25px;
    color: $mediumGrey;
    font-family: 'GothamBold';
    font-size: 1.125rem; // 18px
    font-weight: 700;
    margin: 0 0.5em;
    padding: 0;
    text-transform: none;

    .MuiButton-label {
      background: linear-gradient(to right, rgba(white,0.9), rgba(white,0.9));
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      border-radius: 30px;
      padding: 0.5em 1em;
    }
  }

  .MuiButtonActive {
    background: linear-gradient(90deg, rgba(#EE7601,0.1) 0%, rgba(#E7216E,0.1) 100%),
                linear-gradient(90deg, #EE7601 0%, #E7216E 100%);
    color: $veryDarkGrey;
  }
}

.leaderboards_nav_title {
  font-family: 'GothamBook';
  font-size: 1rem;
  font-weight: 400;

  @include mediabreakpointup(sm) {
    margin-bottom: 0;
    margin-right: 1em;
  }
}
