@import "./src/scss/variables";

$moneyraised-media2-offset: 5em;

.moneyRaisedRange {
  max-width: 950px;
  margin-left: auto;
  margin-right: auto
}

.moneyRaisedFlex {
  align-items: flex-start;
  display: flex;
  margin: 2em auto 0;
  max-width: 980px;


  @include mediabreakpointup(lg) {
    margin-bottom: $moneyraised-media2-offset;
  }
}

.moneyRaisedBody {
  width: 80%;

  @include mediabreakpointup(lg) {
    width: 50%;
  }
}

.moneyRaisedTitleTotal {
  display: block;
}

.moneyRaisedMedia {
  display: none;

  @include mediabreakpointup(lg) {
    display: block;
    position: relative;
    width: 50%;
    padding-left: 10px;
  }
}

.moneyRaisedMediaItem1 {
  height: 376px;
  margin-left: auto;
  width: 376px;
}

.moneyRaisedMediaItem2 {
  bottom: -$moneyraised-media2-offset;
  height: 208px;
  left: 1em;
  position: absolute;
  width: 208px;
}