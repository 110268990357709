@import "./src/scss/variables";

.header_inner {
  display: flex;
  justify-content: space-between;
  padding: 1em 0;

  @include mediabreakpointup(sm) {
    padding: 2em 0;
  }
}

.header_brand {
}

.header_brand_logo {
  height: 60px;
  width: 120px;

  @include mediabreakpointup(sm) {
    height: 80px;
    width: 160px;
  }
}

.header_nav {
  display: flex;
  flex-direction: column;

  @include mediabreakpointup(sm) {
    margin-top: 0.625em;
  }

  .MuiButton-root {
    background-color: #E5E5E5;
    border-radius: 0;
    color: $darkGrey;
    font-family: 'GothamBook', sans-serif;
    font-size: 1rem; // 16px
    font-weight: 400;
    line-height: 1.25;
    padding: 0.75em 1em;
    text-transform: none;
  }
}

#header-nav-menu .MuiMenuItem-root {
  font-family: 'GothamBook', sans-serif;
  font-size: 1rem; // 16px
  font-weight: 400;
}