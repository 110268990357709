@import "./src/scss/variables";

.whatwearedoingTitle {
  margin-bottom: 1em;
  text-align: center;

  @include mediabreakpointup(md) {
    margin-bottom: 1.75em;
  }
}

.whatwearedoingGrid {
  display: grid;
  justify-content: center;

  @include mediabreakpointup(md) {
    display: flex;
  }
}
