@import "./src/scss/variables";

button.btn, a.btn {
  font-family: 'GothamBold', sans-serif;
  font-size: 16px;
  background-color: $lightGrey;
  border: none;
  color: $darkGrey;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 20px;
  margin-bottom: 20px;

  &:nth-last-child(1) {
    margin-right: 0;
  }

  cursor: pointer;
  line-height: 1.2;

  &:hover {
    background-color: $darkGrey;
    color: $lightGrey;
  }

  &.btDonate {
    background: $gradientRgb;
    background: $gradient;
    color: white;
    text-shadow: 1px 1px #000;
    padding-left: 60px;
    &:hover {
      background: $darkGrey;
      color: $lightGrey;
    }
    &:before {
    background-image: url('../../images/icon_biceps.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    content: ""; // ::before and ::after both require content
    position: absolute;
    width: 24px;
    height: 24px;
    margin-left: -30px;
    margin-top: -3px;
    }
  }
  &.btBkHome {
    padding: 20px 0;
  }
}
