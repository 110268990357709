
// Shared .section spacing
// TODO: .section + .section collapse double padding unless .section has a background color
.section {
  padding-bottom: 3em;
  padding-top: 3em;

  @include mediabreakpointup(md) {
    padding-bottom: 4em;
    padding-top: 4em;
  }
}