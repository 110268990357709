@import "./src/scss/variables";

.header_admin {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.wrap-form {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: white;
  font-family: 'GothamBold', sans-serif;

  h3 {
    text-align: end;
    cursor: pointer;
  }

.btn {
  padding: 8px 20px;
  border-radius: 0;
  overflow: hidden;
  width: 100%;

  &_new-entry {
    width: auto;
    margin-top: 10px;
  }

}

.form-input-material {
  --input-default-border-color: black;
  --input-border-bottom-color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  
  input {
    color: rgba(0, 0, 0, 0.8);
    font-size: 16px;
    max-width: 317px;
    padding: 10px;
    margin: 10px 0 ;
  }
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px;
  color: white;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  box-shadow: 0 0.4px 0.4px rgba(128, 128, 128, 0.109),
    0 1px 1px rgba(128, 128, 128, 0.155),
    0 2.1px 2.1px rgba(128, 128, 128, 0.195),
    0 4.4px 4.4px rgba(128, 128, 128, 0.241),
    0 12px 12px rgba(128, 128, 128, 0.35);
    @include mediabreakpointup(md) {
      padding: 50px 40px;
    }

  h1 {
    margin: 0 0 24px 0;
    color: white;
  }

  .form-input-material {
    margin: 12px 0;
  }

  .btn {
    width: 100%;
    margin: 18px 0 9px 0;
  }
}
}