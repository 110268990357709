@import "./src/scss/variables";

.footer_inner {
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 1fr;
  padding: 2em 0;
  display: flex;
  flex-wrap: wrap;
  @include mediabreakpointup(sm) {
    display: grid;
    grid-row-gap: 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    padding: 3em 0; 
  }

  @include mediabreakpointup(lg) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

.footer_brand {
  display: flex;
  flex-direction: column;
  grid-area: 1 / 1 / 2 / 2;
  justify-content: space-between;

  @include mediabreakpointup(sm) {
    grid-area: 1 / 1 / 2 / 2;
  }
}

.footer_brand_logo {
  filter: opacity(0.5);
  height: 60px;
  margin-bottom: 1.25em;
  margin-top: -10px;
  width: 120px;
}

.footer_brand_text {
  color: $mediumGrey;
  font-size: 0.8125rem; // 13px
  margin-bottom: 0;
}

.footer_social {
  display: flex;
  flex-direction: column;
  grid-area: 1 / 2 / 2 / 3;
  justify-content: space-between;

  @include mediabreakpointup(sm) {
    grid-area: 1 / 2 / 2 / 3;
  }
}

.footer_social_icons {
  display: flex;

  img {
    height: 24px;
    margin-left: 0.75em;
      @include mediabreakpointup(md) {
        margin-left: 2em;
      }
    }
}

.footer_social_login {
  a {
    align-items: center;
    display: flex;
  }
}

.footer_social_login_text {
  font-family: 'GothamBold', sans-serif;
  font-size: 0.8125rem; // 13px
  font-weight: 700;
  border-bottom: 1px solid $veryDarkGrey;
}

.footer_social_login_icon {
  display: inline-block;
  height: 15px;
  margin-left: 10px;
  width: 15px;
}

.footer_logos {
  display: flex;
  grid-area: 2 / 1 / 3 / 3;
  justify-content: space-between;

  @include mediabreakpointup(sm) {
    grid-area: 1 / 3 / 2 / 5;
    justify-content: flex-end;
  }

  @include mediabreakpointup(lg) {
    grid-area: 1 / 4 / 2 / 6;
  }
}

.footer_logos_ambassador {
  height: 90px;
  margin-right: 1em;
  width: 90px;

  @include mediabreakpointup(md) {
    margin-right: 4em;
  }
}

.footer_logos_high5ives {
  height: 130px;
  width: 134px;
}
