@import "./src/scss/variables";

$welcome-media2-offset: 4em;

.welcome {
  border-bottom: 1px solid #E8E8E8;
}

.welcomeFlex {
  display: flex;
  margin: 0 auto;

  @include mediabreakpointup(lg) {
    margin-bottom: $welcome-media2-offset;
  }
}

.welcomeBody {
  width: 100%;

  @include mediabreakpointup(lg) {
    padding-top: $welcome-media2-offset;
    width: 50%;
  }

  h1 {
    max-width: 520px;
  }

  p {
    max-width: 480px;
  }
}

.welcomeMedia {
  display: none;

  @include mediabreakpointup(lg) {
    display: block;
    position: relative;
    width: 50%;
  }
}

.welcomeMediaItem1 {
  height: 400px;
  margin-left: auto;
  margin-right: 5em;
  width: 400px;
}

.welcomeMediaItem2 {
  bottom: -$welcome-media2-offset;
  height: 234px;
  position: absolute;
  right: 0;
  width: 234px;
}
