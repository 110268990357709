
$white: #FFFFFF;
$veryDarkGrey: #333333;
$veryDarkGreyBlack: #292929;
$darkGrey: #454545;
$mediumGrey: #666666;
$lightGrey: #D8D8D8;
$veryLightGrey: #F2F2F2;
$brightRed: #E54E3A;
$gradientRgb: rgb(238,118,1);
$gradient: linear-gradient(90deg, rgba(238,118,1,1) 0%, rgba(231,33,110,1) 50%);

$grid-breakpoint-xs: 375px;
$grid-breakpoint-sm: 576px;
$grid-breakpoint-md: 768px;
$grid-breakpoint-lg: 992px;
$grid-breakpoint-xl: 1200px;
$grid-breakpoint-xxl: 1450px;

@mixin mediabreakpointup($point) {
  @if $point == xs {
    @media (min-width: $grid-breakpoint-xs) {
      @content;
    }
  } @else if ($point == sm) {
    @media (min-width: $grid-breakpoint-sm) {
      @content;
    }
  } @else if ($point == md) {
    @media (min-width: $grid-breakpoint-md) {
      @content;
    }
  } @else if ($point == lg) {
    @media (min-width: $grid-breakpoint-lg) {
      @content;
    }
  } @else if ($point == xl) {
    @media (min-width: $grid-breakpoint-xl) {
      @content;
    }
  } @else if ($point == xxl) {
    @media (min-width: $grid-breakpoint-xxl) {
      @content;
    }
  } @else {
    @media (min-width: $point) {
      @content;
    }
  }
}
