@import "./src/scss/variables";

.moneyRaisedRange {
  display: none;
}

@include mediabreakpointup(lg) {
  .moneyRaisedRange {
    display: block;
  }
}

.MuiSlider-mark {
  display: none;
}
.MuiSlider-marked{
  color: green!important;
}

.range_label {
  color: $lightGrey;
  font-family: 'GothamBold';
  font-weight: 700;
  align-self: center;
  margin-right: -40px;
  &_goal {
    float: right;
    margin-right: -132px;
    margin-left: 40px;
    color: $darkGrey;
    &:before {
    background-image: url('../../images/icon_trophy-black.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px 24px;
    content: "";
    position: relative;
    width: 24px;
    height: 24px;
    margin-left: 7px;
    margin-top: 8px;
    display: block;
    }
  }
}

.thumb-component {
  display: grid;
  min-width: 100px;
  text-align: center;
  justify-content: center;
  margin-top: -130px;
  margin-left: 10px;
  position: absolute;
  img {
    width: 32px;
    margin-top: 10px;
    justify-self: center;
  }

  p {
    margin: 10px 0;
  }

  &:after {
    content: '';
    width: 0;
    height: 0;
    border-radius: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #333;
    margin: 0 auto;
  }
}

.MuiSlider-markLabel {
  white-space: normal!important;
  width: 100px;
  text-align: center;
}