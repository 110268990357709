@import "./src/scss/variables";

.leaderboard {
  background-color: $veryLightGrey;
}

.leaderboard_header {
  margin-bottom: 2.5em;
  text-align: center;

  >:last-child {
    margin-bottom: 0;
  }
}

.leaderboard_header_title {
  margin-bottom: 0.5em;
}

.leaderboard_header_text {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 780px;
}

.leaderboard_body {
  background-color: $white;
  border-radius: 6px;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  color: $veryDarkGrey;
  margin-left: auto;
  margin-right: auto;
  padding: 2em;

  @include mediabreakpointup(lg) {
    width: 980px;
  }
}
