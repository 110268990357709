@font-face {
  font-display: swap;
  font-family: 'GothamBook';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/GothamBook.woff2') format('woff2'),
       url('../fonts/GothamBook.woff') format('woff');
       // local('Gotham Book'), local('Gotham-Book');
}

@font-face {
  font-display: swap;
  font-family: 'GothamBold';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/GothamBold.woff2') format('woff2'),
       url('../fonts/GothamBold.woff') format('woff');
       // local('Gotham Bold'), local('Gotham-Bold');
}

@font-face {
  font-display: swap;
  font-family: 'GothamBoldItalic';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/GothamBoldItalic.woff2') format('woff2'),
       url('../fonts/GothamBoldItalic.woff') format('woff');
       // local('Gotham BoldItalic'), local('Gotham-BoldItalic');
}

@font-face {
  font-display: swap;
  font-family: 'GothamBlack';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/GothamBlack.woff2') format('woff2'),
       url('../fonts/GothamBlack.woff') format('woff');
       // local('Gotham Black'), local('Gotham-Black');
}
