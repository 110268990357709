@import "./src/scss/variables";

.leaderboardtable {
  .MuiTable-root {
    @include mediabreakpointup(lg) {
      min-width: 720px;
    }
  }

  .MuiTableCell-root {
    font-family: 'GothamBook';
    font-weight: 400;
    padding: 0.5em 0.25em;
  }

  .MuiTableCell-body {
    border-bottom: 0;
    color: #444444;
    font-size: 0.8125rem; // 13px
    padding: 1.25em 0.5em;
  }

  // Hides sport columns on mobile
  .MuiTableRow-root .MuiTableCell-root {
    &:not(:first-child):not(:last-child) {
      @media (max-width: $grid-breakpoint-md) {
        display: none;
      }
    }
  }

  // Head
  .MuiTableHead-root > .MuiTableRow-root {
    .MuiTableCell-head {
      color: $mediumGrey;
      font-size: 1rem;
      vertical-align: top;

      // Name
      &:first-child {
        width: 30%;
      }

      // Total
      &:last-child {
        font-family: 'GothamBold';
        font-weight: 700;
        width: 15%;
      }
    }
  }

  // Body
  .MuiTableBody-root > .MuiTableRow-root {
    // Name and total column bold
    .MuiTableCell-body {
      &:first-child,
      &:last-child {
        color: $veryDarkGrey;
        font-family: 'GothamBold';
        font-weight: 700;
      }

      // Name with icon
      &:first-child {
        position: relative;
        padding-left: 2.75em;

        img {
          margin-top: -2px;
          left: 0;
          position: absolute;
        }
      }
    }

    // First row in body give extra top padding
    &:first-child {
      td,
      th {
        padding-top: 2em;
      }
    }
  }
}

.leaderboardtable_th_icon {
  display: none;

  @include mediabreakpointup(md) {
    display: block;
    height: 40px;
    margin-bottom: 5px;
    width: 40px;
  }

  img {
    display: block;
  }
}

.leaderboardtable_th_label {
  color: $brightRed;
  display: block;
  font-family: 'GothamBold';
  font-size: 0.625rem; // 10px
  font-weight: 700;
  text-transform: uppercase;
}
