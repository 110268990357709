@import-normalize;
@import "./src/scss/variables";
@import "./src/scss/fonts";
@import "./src/scss/colors";
@import "./src/scss/typography";
@import "./src/scss/layout";

body {
  background-color: $white;
  color: $veryDarkGrey;
  // color: #1D1D1D;
  margin: 0;
}

// Aspect Ratio
//
.aspectRatio { height: 0; margin: 0; position: relative; overflow: hidden; width: 100%; }
.aspectRatioMedia { bottom: 0; height: 100%; left: 0; position: absolute; right: 0; top: 0; width: 100%; }
.aspectRatio-1x1 { padding-bottom: 100%; }
