@import "./src/scss/variables";

.block {
  margin-bottom: 1em;
  text-align: center;

  @include mediabreakpointup(md) {
    margin-bottom: 0;
    width: 33.333333333%;

    &:not(:last-child) {
      border-right: 1px solid #E5E5E5;
    }
  }

  &_inner {
    display: grid;
    margin: 0 auto;
    padding: 1.25em;
    max-width: 300px;

    @include mediabreakpointup(md) {
      padding: 2.25em 1.25em;
    }
  }

  &_icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    justify-self: center;
    height: 50px;
    width: 50px;

    &-pound {
      background-image: url('../../../../images/icon_pound.png');
    }

    &-heart {
      background-image: url('../../../../images/icon_heart.png');
    }

    &-hand {
      background-image: url('../../../../images/icon_thumbs-up.png');
    }
  }

  &_title {
    margin: 0.35em 0;
  }

  &_text {
    color: #595959;
    font-size: 0.8125rem; // 13px
    letter-spacing: -0.29px;
    line-height: 1.538461538; // 20px
    margin-bottom: 0;
  }
}
