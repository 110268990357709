html {
  font-family: sans-serif;
  font-size: 16px;
  font-family: sans-serif;
  text-rendering: optimizeLegibility;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: 'GothamBook', sans-serif;
  font-size: 1rem; // 16px
  font-weight: 400;
  line-height: 1.5; // 24px
}

a {
  text-decoration: none;
  color: $veryDarkGrey;
}

p {
  margin: 0 0 1.25em;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 0.5em;
}

h1 {
  color: $veryDarkGreyBlack;
  font-family: 'GothamBlack', sans-serif;
  font-size: 3.375rem; // 54px
  font-weight: 900;
  letter-spacing: -0.75px;
  line-height: 1.037037037; // 56px
  margin-bottom: 0.75em;
}

h2 {
  color: $veryDarkGreyBlack;
  font-family: 'GothamBold', sans-serif;
  font-size: 2.25rem; // 36px
  font-weight: 700;
  letter-spacing: -0.5px;
  line-height: 1.166666667; // 42px
  margin-bottom: 0.75em;
}

h3 {
  color: $veryDarkGrey;
  font-family: 'GothamBold', sans-serif;
  font-size: 1.375rem; // 22px
  font-weight: 700;
  letter-spacing: -0.31px;
  line-height: 1.909090909; // 42px
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.fontBook {
  font-family: 'GothamBook';
  font-weight: 400;
}

.fontBold {
  font-family: 'GothamBold';
  font-weight: 700;
}

.fontBlack {
  font-family: 'GothamBlack';
  font-weight: 900;
}

.underline {
  border-bottom: 1px solid $veryDarkGrey;
}

.text-center {
  text-align: center;
}
